<template>
<div>
    <section>
        <div class="div-block-1232">
            <div class="w-layout-blockcontainer container-19 w-container">
                <a href="#" class="w-inline-block"><img :src="mainlogo" loading="lazy" alt="" class="image-212"></a>
            </div>
            <div class="div-block-1205">
                <div class="text-block-271">One last step {{ first_name }}.</div>
                <p class="paragraph-70">Before your Doctor visit we need to collect some basic medical information. This is the same form you would fill out when seeing a new doctor in person. After complete you can schedule your teledoc visit.</p>
                <a href="" @click="pushToRoute($event)" class="button-50 w-button">Get Started -&gt;</a>
            </div>
        </div>
    </section>
    <div class="included-space" style="height:98px;"></div>
    
</div>
</template>

<script>


export default {
    props: ['baseUrl', 'mainlogo'],
    components: {
       
    },
    data() {
        return {

        }
    },
    computed: {
        first_name() {
            try {
                let k = localStorage.getItem("formResponse");
                if (k) {
                    let temp = JSON.parse(k);
                    return temp.first_name;
                }
            } catch (error) {
                console.log(error);

            }
            return "";
        }
    },
    methods: {
        pushToRoute(event) {
            event.preventDefault();
            this.$router.push({
                name: 'FullQuestionStageTwo',
                query: {
                    "health_id": this.$route.query.health_id,
                    "id": this.$route.query.id,
                    "plan": this.$route.query.plan
                }
            });
        }
    },
    created() {
        try {
            const query = this.$route.query;
            const validAffiliateIds = [164, 2]; // List of valid affiliate IDs
            if (validAffiliateIds.includes(Number(query.utm_campaign)) && query.offer_id) {
                let data = {
                    offer_id: parseInt(query.offer_id),
                    aid: parseInt(query.utm_campaign),
                    transaction_id: '',
                };

                window.EF.conversion(data);
                console.log("Event Fired ", data)
            }
        } catch (error) {
            console.error("Tracking Error:", error);
        }
        try {
            // Check if gtag function is loaded
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'conversion', {
                    'transaction_id': this.$route.query.health_id || 'default_id',
                    'value': this.$route.query.amount || 1,
                    'currency': 'USD',
                });
            } else {
                console.warn('gtag is not available. Make sure Google Tag Manager or gtag.js is properly loaded.');
            }
            try{
                window.fbq('track', 'Purchase', {currency: "USD", value: 1});
            }catch(error){
                console.log(error)
            }
        } catch (error) {
            console.error('Error sending Google Tag Manager event:', error);
        }
        try{
            window.obApi('track', 'Purchase');
        }catch(error){
            console.log(error);
        }
    }
}
</script>
